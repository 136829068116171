export const ValidationMessages = {
  required: 'Campo obrigatório!',
  passesDontMatch: 'Senhas não conferem',
  nome: {
    required: 'Informe seu nome',
    rangeLength: 'O nome deve possuir entre 2 e 50 caracteres',
    validName: 'Informe somente letras e espaços',
  },
  sobrenome: {
    required: 'Informe seu sobrenome',
    rangeLength: 'O sobrenome deve possuir entre 2 e 50 caracteres',
  },
  cpf: {
    required: 'Informe o CPF',
    cpf: 'CPF inválido',
  },
  rg: {
    required: 'Informe o RG',
    rangeLength: 'O RG deve possuir de 6 a 14 caracteres',
  },
  apelido: {
    required: 'Informe seu apelido',
    rangeLength: 'O apelido deve possuir entre 2 e 40 caracteres',
    validNickname: 'Informe somente letras',
  },
  dataNascimento: {
    required: 'Informe sua data de nascimento',
    validAdult: 'Você precisa ter mais de 18 anos',
    invalidDate: 'Informe uma data de nascimento válida',
    oldPerson: 'Informe uma data entre 18 e 100 anos',
  },
  genero: {
    required: 'Escolha um gênero',
  },
  senha: {
    required: 'Informe sua senha',
    rangeLength: 'A senha deve possuir entre 6 e 15 caracteres',
    typeLetter: 'A senha deve conter número, letra maiúscula e minúscula',
    space: 'A senha não pode ter espaços em branco',
  },
  confirmaSenha: {
    passesDontMatch: 'As senhas não conferem',
  },
  cep: {
    required: 'Informe o CEP',
    cep: 'CEP em formato inválido',
  },
  logradouro: {
    required: 'Informe o Logradouro',
    rangeLength: 'O logradouro deve possuir entre 1 e 100 caracteres',
  },
  numero: {
    required: 'Informe o Número',
    rangeLength: 'O número deve possuir entre 1 e 10 caracteres',
  },
  rua: {
    required: 'Informe a rua',
    rangeLength: 'A rua deve possuir entre 1 e 100 caracteres',
  },
  bairro: {
    required: 'Informe o Bairro',
    rangeLength: 'O bairro deve possuir entre 1 e 50 caracteres',
  },
  cidade: {
    required: 'Informe a Cidade',
    rangeLength: 'A cidade deve possuir entre 1 e 50 caracteres',
  },
  complemento: {
    rangeLength: 'O complemento deve possuir entre 1 e 50 caracteres',
  },
  estado: {
    required: 'Informe o Estado',
  },
  outraEmpresa: {
    rangeLength: 'O nome das empresas deve possuir entre 1 e 50 caracteres',
  },
  celular: {
    required: 'Informe o celular',
    rangeLength: 'Celular inválido',
  },
  telefoneOutros: {
    telefone: 'Telefone inválido',
  },
  email: {
    required: 'Informe o e-mail',
    email: 'E-mail inválido',
    rangeLength: 'O e-mail deve possuir entre 1 e 100 caracteres',
  },
  confirmedEmail: {
    required: 'Informe o e-mail',
    email: 'E-mail inválido',
    equalTo: 'Os e-mails não conferem',
  },
  cnpj: {
    invalidCNPJ: 'CNPJ inválido',
    rangeLength: 'O CNPJ deve possuir entre 1 e 20 caracteres',
  },
  razaoSocial: {
    required: 'Informe a razão social',
    rangeLength: 'A razão social deve possuir entre 1 e 200 caracteres',
  },
  inscricaoEstadual: {
    required: 'Informe a inscrição estadual',
    rangeLength: 'A inscrição social deve possuir entre 1 e 20 caracteres',
  },
  cidadeLoja: {
    required: 'Selecione a cidade da sua loja',
  },
  distribuidorId: {
    required: 'Selecione uma loja',
  },
};
